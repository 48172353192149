import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import { Container } from '../components/styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  GithubIcon,
  LinkedinIcon,
  TwitterIcon,
} from '../components/social-icons';
import background from '../../content/assets/index-background.jpg';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      to right,
      rgba(33, 33, 33, 0.67),
      rgba(66, 66, 66, 0.67)
    ),
    url(${background}) no-repeat fixed center;
  background-size: cover;
  color: #ffffff;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
`;

const Description = styled.div`
  margin-bottom: 30px;
  font-family: Ubuntu;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2;
  text-align: center;
`;

const AnchorsGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const A = styled.a`
  margin-right: 30px;
  box-shadow: none;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }
`;

export default function Index({ data }) {
  const author = data.site.siteMetadata.author;

  return (
    <Wrapper>
      <SEO title={author.name} />

      <Header />

      <Main>
        <Container>
          <Title>{author.name}</Title>
          <Description>{author.summary}</Description>

          <AnchorsGroup>
            <A href="https://github.com/drihu" target="_blank" rel="noreferrer">
              <GithubIcon width="45" color="#ffffff" />
            </A>

            <A
              href="https://www.linkedin.com/in/ricardo-huamani-parian/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIcon width="45" color="#ffffff" />
            </A>

            <A
              href="https://twitter.com/drihudev"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon width="45" color="#ffffff" />
            </A>
          </AnchorsGroup>
        </Container>
      </Main>

      <Footer />
    </Wrapper>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author {
          name
          summary
        }
      }
    }
  }
`;
